<template>
  <v-row>
    <v-btn
      color="RMwhite"
      class="pa-0 ma-1"
      min-height="40"
      min-width="40"
      title="Screenshot"
      @click="captureScreenshot()"
    >
      <v-icon>
        mdi-fit-to-screen-outline
      </v-icon>
    </v-btn>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['map'])
  },
  methods: {
    ...mapActions(['storeToastMessage']),
    async captureScreenshot() {
      try {
        const scaleFactor = 2;
        const size = this.map.OLmap.getSize(); // Ottieni le dimensioni della mappa
        const canvas = document.createElement('canvas');
        canvas.width = size[0] * scaleFactor;
        canvas.height = size[1] * scaleFactor;
        const ctx = canvas.getContext('2d');
        ctx.scale(scaleFactor, scaleFactor);

        // Cattura la mappa di base
        const baseMapImage = await this.captureBaseMap();

        // Disegna la mappa di base sul canvas
        await this.drawImageOnCanvas(baseMapImage, ctx, size);

        // Disegna i layer sopra la mappa di base
        await this.drawLayerCanvases(ctx, size);

        const a = document.createElement('a');
        a.href = canvas.toDataURL('image/png', 1.0);
        a.download = 'Radarmeteo_MeteoCast_Screenshot.png';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        this.storeToastMessage({
          text: 'Errore durante la cattura dello screenshot',
          type: 'error'
        });
        throw error;
      }
    },
    async captureBaseMap() {
      return new Promise((resolve) => {
        this.map.MBmap.once('render', () => {
          resolve(this.map.MBmap.getCanvas().toDataURL());
        });
        this.map.MBmap.setBearing(this.map.MBmap.getBearing()); // Forza un re-render
      });
    },
    async drawImageOnCanvas(imageSrc, ctx, size) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imageSrc;

        img.onload = () => {
          ctx.drawImage(img, 0, 0, size[0], size[1]);
          resolve();
        };

        img.onerror = reject;
      });
    },
    async drawLayerCanvases(ctx, size) {
      const layerCanvases = document.querySelectorAll('.ol-layer canvas, canvas.ol-layer');

      layerCanvases.forEach((c) => {
        if (c.width > 0 && c.height > 0) {
          try {
            const opacity = parseFloat(window.getComputedStyle(c.parentElement).opacity) || 1;
            ctx.globalAlpha = opacity;
            ctx.drawImage(c, 0, 0, size[0], size[1]);
          } catch (e) {
            this.storeToastMessage({
              text: 'Errore durante la cattura dello screenshot',
              type: 'error'
            });
            throw e;
          }
        }
      });
    }
  }
};
</script>
